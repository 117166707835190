import { ShopifyProductVariant } from './shopify/shopify_product_variant.class';

export interface License {
  license_id: number;
  discipline_id: number;
  is_domestic: boolean;
  is_collegiate: boolean;
  is_professional: boolean;
  is_international: boolean;
  is_uci?: boolean;
  club: number;
  category_id: number;
  category_name: string;
  is_premium: boolean;
  license_type: number;
  license_type_name: string;
  status: LicenseStatus;
  effective_date: string;
  expiration_date: string;
  discipline_name?: string;
  club_name?: string;
  team_name?: string;
}

// TODO: @Charlie - Confirm these model names and that they match API resource(s)
export interface LicenseGroup {
  type: number;
  type_name: string;
  status: LicenseStatus;
  expiration: string;
  licenses: License[];
}

export interface LicenseType {
  id: number;
  name: string;
  license_categories: LicenseCategory[];
}

export interface LicenseCategory {
  id: number;
  name: string;
  description: string;
  expiration?: Date;
  status?: LicenseCategoryStatus;
  is_active?: boolean;
  shopify_variant?: ShopifyProductVariant;
  base_shopify_variant?: ShopifyProductVariant;
  requirements: LicenseRequirement[];
  requirements_completed?: boolean;
  requirements_blocking?: boolean;
}

export interface LicenseCategoryGroup {
  baseVariant: ShopifyProductVariant;
  categories: LicenseCategory[];
}

export interface LicenseRequirement {
  name: string;
  description: string;
  completed: boolean;
  allow_pending: boolean;
  action?: string;
}

export enum LicenseCategoryStatus {
  ELIGIBLE = 'Eligible',
  EXPIRED = 'Expired',
  INELIGBLE = 'Ineligible',
  INVALID = 'Invalid',
  VALID = 'Valid',
}

export enum LicenseFlag {
  INTERNATIONAL = 'is_international',
  PREMIUM = 'is_premium',
  PROFESSIONAL = 'is_professional',
  UCI = 'is_uci',
}

export enum LicenseStatus {
  PENDING = 'P',
  RESOLVED = 'R',
  SUSPENDED = 'S',
}

export enum LicenseTypeId {
  DOMESTIC = 13,
  INTERNATIONAL = 14,
  PROFESSIONAL = 15,
  COLLEGIATE = 16,
  RIDE_MEMBERSHIP = 17,
  COACHES = 18,
  MECHANICS = 19,
  RACE_DIRECTOR = 20,
  OFFICIALS = 21,
  DRIVERS = 22,
  BMX = 23,
  INTERNATIONAL_COACH = 24,
  UCI_SUPPORT = 25,
  TEAM_MANAGER = 26,
}
