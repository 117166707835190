import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LicenseCategory, LicenseRequirement } from 'src/app/core/models';
import { ShopifyProductVariant } from 'src/app/core/models/shopify/shopify_product_variant.class';

export interface Credentials {
  email: string;
  password: string;
}
@Component({
  selector: 'app-requirements-modal',
  templateUrl: './requirements-modal.component.html',
  styleUrls: ['./requirements-modal.component.scss'],
})
export class RequirementsModalComponent implements OnInit {
  @Input() baseVariant: ShopifyProductVariant;
  @Input() categories: LicenseCategory[] = [];

  requirements: LicenseRequirement[] = [];

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.mapRequirements();
  }

  private mapRequirements(): void {
    const requirements: LicenseRequirement[] = [];
    this.categories.forEach((category) => {
      category.requirements.forEach((requirement) => {
        if (!requirements.some((req) => req.name === requirement.name)) {
          requirements.push(requirement);
        }
      });
    });
    this.requirements = requirements;
  }

  iconClass(requirement: LicenseRequirement): string {
    return requirement.completed ? 'icon-check-circle text-success' : 'icon-warning text-warning';
  }

  actionText(requirement: LicenseRequirement): string {
    let text = 'Completed';
    if (!requirement.completed) {
      text = requirement.action ? 'Take Action' : 'Contact Us';
    }
    return text;
  }

  checkPending(category: LicenseCategory): boolean {
    return category.requirements.some((req: LicenseRequirement) => req.completed === false);
  }

  openActionLink(requirement: LicenseRequirement): void {
    const url = requirement.action || 'mailto:membership@usacycling.org';
    window.open(url, '_blank');
  }

  closeModal(): void {
    this.activeModal.close();
  }

  get category(): LicenseCategory {
    return this.categories.length === 1 ? this.categories[0] : null;
  }

  get title(): string {
    return this.baseVariant ? this.baseVariant.title : this.category.name;
  }

  // get description(): string {
  //   // TODO: @Charlie - Where would we get a "group" description?
  //   return this.baseVariant ? '' : this.category.description;
  // }

  get showExpiration(): boolean {
    return this.baseVariant
      ? this.categories.some((category) => !!category.expiration)
      : !!this.category.expiration;
  }

  get expiration(): Date {
    if (this.baseVariant) {
      const expirationDates = this.categories
        .map((category) => category.expiration)
        .filter((expiration) => !!expiration);
      return expirationDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
    } else {
      return this.category.expiration;
    }
  }

  get requirementsPending(): boolean {
    return this.baseVariant
      ? this.categories.every((category) => this.checkPending(category))
      : this.checkPending(this.category);
  }

  get requirementStatus(): string {
    return this.requirementsPending ? 'Requirements Pending' : 'Requirements Met';
  }

  get requirementClass(): string {
    return this.requirementsPending ? 'text-warning' : 'text-success';
  }
}
